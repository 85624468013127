import React, { Component } from "react"
import styled from "styled-components"

class BoxTwo extends Component {
  render() {
    return (
      <MainBox>
        <Box>
          <BoxHeader>Leads to Closure</BoxHeader>
          <BoxContent>
            Keep an eye on key data, metrics and analytics on your customers and
            opportunities. Visualized and presented rich dashboards enables you
            to optimize your business for enhanced customer engagement.
          </BoxContent>
        </Box>
      </MainBox>
    )
  }
}
export default BoxTwo

const MainBox = styled.div`
  background: #2a1e5c;

  display: grid;
  justify-content: center;
  align-content: center;
  /* @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  } */
`
const Box = styled.div`
  text-align: center;
  height: 50vh;
  width: 50vw;
  display: grid;
  justify-content: center;
  align-content: center;
  @media screen and (max-width: 600px) {
    height: auto;
    width: auto;
    padding: 20px;
  }
`
const BoxHeader = styled.h2`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 2.75em;
  text-align: center;
  color: #ffffff;
`
const BoxContent = styled.p`
  font-family: "Roboto";
  font-weight: 100;
  font-size: 1.125em;
  text-align: center;
  color: #eafaff;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
