import React, { Component } from "react"

import LEADSFIRSTIMAGE from "../../../images/leadstoclosure/first.jpg"
import LEADSSECONDIMAGE from "../../../images/leadstoclosure/second.jpg"
import CIRCLETICK from "../../../images/greencircletick.png"

import styled from "styled-components"
class BoxThree extends Component {
  render() {
    return (
      <MainBox>
        {/* Out Of The Box */}
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={LEADSFIRSTIMAGE} alt="first" width="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Perspective views to get new perspectives
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Stay on top of each opportunities and metrics that help you stay
                miles ahead for better customer experiences and increased
                closure rates.
              </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Capture leads automatically
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Categorized and populated in themes, each tile presents
                key findings and reportings.
                <br />
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img src={LEADSFIRSTIMAGE} alt="first" width="100%" />
            </ImageBox>
          </BoxRight>
        </Box>
        <Spacer />
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={LEADSSECONDIMAGE} alt="second" width="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxRight>
            <ImageBox>
              <img src={LEADSSECONDIMAGE} alt="second" width="100%" />
            </ImageBox>
          </BoxRight>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Get directions on your Customer
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Gain insights on what’s happening with each customer globally at
                a single glance. Combined with map visualisation and detailed
                analytics helps you coordinate, enhance and pave the right path
                for your teams to enhance sales outreach efforts.
              </BoxLeftContent1>
            </ContentWrapper>
          </BoxLeft>
        </Box>
        <Spacer />
      </MainBox>
    )
  }
}
export default BoxThree

const ContentWrapper = styled.div`
  /* border: 3px solid yellow; */
`

const MainBox = styled.div`
  padding: 40px;
  /* border: 1px solid red; */
  /* background: #eafaff; */
  @media screen and (max-width: 600px) {
    padding: 10px;
  }
`

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    padding: 0px;
  }

  /* margin-top: 20px; */
`
const BoxLeft = styled.div`
  width: 45%;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 100%;
  }
`

const BoxLeftMainHeader = styled.h2`
  font-family: "Roboto";
  font-size: 2.2em;
  font-weight: 500;
  text-align: left;
  margin: 0 auto;
  letter-spacing: -0.5px;
  color: #080612;
`
const BoxLeftContent1 = styled.p`
  font-family: "Roboto";
  font-size: 1.1em;
  text-align: left;
  color: #6b6a71;
  padding: 10px 0px;
  font-weight: 100;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const BoxLeftContent = styled.p`
  font-family: "Roboto";
  font-weight: 100;
  padding: 0px 10px;
  font-size: 1.1em;
  text-align: left;
  color: #6b6a71;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`

const BoxRight = styled.div`
  width: 52%;
  /* padding: 0px 30px;
  margin-right: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  @media screen and (max-width: 600px) {
    width: 100%;
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const ImageBox = styled.div`
  border: 11px solid rgb(221, 221, 221);
  border-radius: 7px;
  /* height: 480px; */
  background-color: whitesmoke;
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    /* height: 220px; */
    margin: 20px 10px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* height: 320px; */
    margin: 20px 10px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* height: 420px; */
    margin: 20px 10px;
  }
`
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 70px;
`
const BoxRightMobile = styled.div`
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: block;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: none;
  }
`
