import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Leads from "../modules/SubFetaures/Leads"

const leadsopportunitiesandClosures = () => (
  <Layout>
    <SEO title="Leads, Opportunities and Closures"
    description="Key data and analytics regarding customer and opportunity presented in a rich UI for better customer engagement." />
    <Leads />

    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default leadsopportunitiesandClosures
