import React from "react"
import BoxFour from "./LeadsToClosureNewPage/BoxFour"
import BoxOne from "./LeadsToClosureNewPage/BoxOne"
import BoxTwo from "./LeadsToClosureNewPage/BoxTwo"
import BoxThree from "./LeadsToClosureNewPage/BoxThree"
import styled from "styled-components"
function Leads() {
  return (
    <div>
      <BoxOne />
      <BoxTwo />
      <Spacer />
      <BoxThree />
      <BoxFour />
    </div>
  )
}

export default Leads
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 30px;
  @media screen and (max-width: 600px) {
    height: 30px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 30px;
  }
`
